<template>
  <div class="photo">
    {{ label }}
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style scoped>
.photo {
  text-align: center;
}
</style>